// Here you can add other styles

body {
  line-height: 1;
}

.rdtPicker {
  width: 100% !important;
}

#DateTimePatient .rdtPicker td,
#DateTimePatient .rdtPicker th {
  height: 15px !important;
}

.rx-history-list {
  height: 240px;
  overflow-y: scroll;
  cursor: pointer;
}

.rx-history-list .list-group-item {
  padding: 0 !important;
  text-align: center !important;
}

.app-header {
  background-color: #337ab7;
  color: #fff;
}

.app-header a {
  color: #fff;
}

.app-header .nav-link {
  color: #fff !important;
}

.border-dashed {
  border: 1px dashed #e4e7ea;
}

.card-header {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.card-header strong {
  line-height: 28px;
}

.imgPreview {
  max-width: 150px;
  height: auto;
  display: block;
}

.login-logo {
  border: 0;
}

.cursor-pointer {
  cursor: pointer;
}

#witness-confirmation .form-group {
  margin-bottom: 0 !important;
}

.model-header {
  background-color: #f0f3f5;
}

.card {
  margin-bottom: 5px !important;
}
